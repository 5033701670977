import { getRebateAmount } from 'site-modules/shared/utils/incentives/get-rebate-amount-from-incentives';

export function getIncentivesWithMaxRebate(incentives) {
  const incentivesWithSameGrantorAndSubtype = Object.values(
    incentives.reduce((result, incentive) => {
      const { grantor, subtype } = incentive;
      if (grantor && subtype) {
        const key = `${grantor}|${subtype}`;
        return { ...result, [key]: result[key] ? [...result[key], incentive] : [incentive] };
      }
      return result;
    }, {})
  ).filter(inc => inc.length > 1);

  const idsForRemoving = incentivesWithSameGrantorAndSubtype
    .reduce((result, inc) => {
      let maxRebate = 0;
      const idWithMaxRebate = inc.reduce((id, incentive) => {
        const amount = getRebateAmount(incentive);
        if (amount >= maxRebate) {
          maxRebate = amount;
          return incentive.id;
        }
        return id;
      }, '');
      const ids = inc.map(({ id }) => id).filter(id => id !== idWithMaxRebate);
      return [...result, ids];
    }, [])
    .flat();

  return incentives.filter(({ id }) => !idsForRemoving.includes(id));
}
