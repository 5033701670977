import React, { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { get } from 'lodash';
import { Form } from 'reactstrap';
import {
  IncentivesWizardEntities,
  IncentivesWizardModel,
  IncentivesWizardPaths,
} from 'client/data/models/incentives-wizard';
import { VisitorModel } from 'client/data/models/visitor';
import { isUnknownZip } from 'site-modules/shared/utils/location';
import {
  StepProps,
  StepPropsDefaults,
} from 'site-modules/shared/components/incentives/incentives-wizard/prop-types/step-props';
import { validation } from 'site-modules/shared/components/form-validation/validation';
import { StyledZipInput } from 'site-modules/shared/components/styled-zip-input/styled-zip-input';
import { Input } from 'site-modules/shared/components/inventory/input/input';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { Button } from 'client/site-modules/shared/components/inventory/button/button';
import { BaseQuestionStep } from 'site-modules/shared/components/incentives/incentives-wizard/steps/base-question-step';
import { useQuestionTracking } from 'site-modules/shared/components/incentives/incentives-wizard/hooks/use-question-tracking';

import './zip-input-wizard-question.scss';

export const ZipInputWizardQuestionUI = memo(
  ({
    stepConfig,
    stepMeta,
    answers,
    eligibleIncentiveIds,
    incentives,
    allIncentives,
    onAnswer,
    onBack,
    context,
    flowSteps,
    wizardFilters,
    creativeId,
    isInternational,
  }) => {
    const filtersZipCode = get(wizardFilters, 'incentiveFilters.zip', '');
    const [inputValue, setInputValue] = useState(isInternational ? null : filtersZipCode);
    const [handleAnswerTracking] = useQuestionTracking({ stepConfig, creativeId, stepMeta });
    const trimmedInput = inputValue?.trim();

    const handleInputChange = useCallback(newZip => {
      setInputValue(newZip);
    }, []);

    const handleZipChange = useCallback(() => Promise.resolve(), []);

    const handleSubmit = useCallback(
      e => {
        e.preventDefault();

        onAnswer(
          inputValue === filtersZipCode ? [inputValue, { isChanged: false }] : [inputValue, { isChanged: true }]
        );
        handleAnswerTracking(inputValue);
      },
      [onAnswer, inputValue, filtersZipCode, handleAnswerTracking]
    );

    return (
      <BaseQuestionStep
        stepConfig={stepConfig}
        stepMeta={stepMeta}
        answers={answers}
        eligibleIncentiveIds={eligibleIncentiveIds}
        incentives={incentives}
        allIncentives={allIncentives}
        onAnswer={onAnswer}
        onBack={onBack}
        context={context}
        flowSteps={flowSteps}
      >
        <div className="zip-wizard-question d-flex justify-content-center mb-2">
          <Form id="zip-wizard-question-form" noValidate className="pos-r" name="form" onSubmit={handleSubmit}>
            <StyledZipInput
              inputClasses="search-by-zip-input"
              onValidate={validation.validateZip}
              getTracking={{ 'data-tracking-id': 'provide_zip_code' }}
              onInputChange={handleInputChange}
              onZipChange={handleZipChange}
              btnColor="primary-b"
              btnClassname="fw-medium"
              inputComponent={Input}
              inputSize=""
              inputProps={{ inputClassName: 'text-start size-20' }}
              zipCode={filtersZipCode}
              forceEmptyZip={isInternational}
              placeholder="Enter ZIP"
            />
            <Button
              color="outline"
              size="sm"
              type="submit"
              className={classnames(
                'submit-btn bg-cool-gray-70 pos-a d-flex justify-content-center align-items-center rounded-6',
                {
                  'bg-blue-50': trimmedInput,
                }
              )}
              aria-label="Submit"
              disabled={!trimmedInput}
            >
              <span className="icon-arrow-right8 icon text-white size-16" aria-hidden />
            </Button>
          </Form>
        </div>
      </BaseQuestionStep>
    );
  }
);

ZipInputWizardQuestionUI.displayName = 'ZipInputWizardQuestion';

ZipInputWizardQuestionUI.propTypes = {
  ...StepProps,
  wizardFilters: IncentivesWizardEntities.IncentivesWizardFilters.isRequired,
  isInternational: PropTypes.bool,
};
ZipInputWizardQuestionUI.defaultProps = { ...StepPropsDefaults, isInternational: false };

export const stateToPropsConfig = {
  wizardFilters: bindToPath(IncentivesWizardPaths.getIncentivesWizardFilters(), IncentivesWizardModel, null, false),
  isInternational: bindToPath('location', VisitorModel, visitorLocation => isUnknownZip(visitorLocation)),
};

export const ZipInputWizardQuestion = connectToModel(ZipInputWizardQuestionUI, stateToPropsConfig);
