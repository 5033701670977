import { isOEMRebate } from 'site-modules/shared/components/incentives/incentives-wizard/utils/is-oem-rebate';
import { getIncentivesWithMaxRebate } from 'site-modules/shared/components/incentives/incentives-wizard/utils/get-incentives-with-max-rebate';

const filterOutOEM = eligibleIncentives => eligibleIncentives.filter(incentive => !isOEMRebate(incentive));
const filterInOEM = eligibleIncentives => eligibleIncentives.filter(incentive => isOEMRebate(incentive));

export const buildEligibleIncentives = (incentives, eligibleIncentiveIds) => {
  const eligibleIncentives = incentives.filter(incentive => eligibleIncentiveIds.includes(incentive.id));

  return [...filterOutOEM(eligibleIncentives), ...getIncentivesWithMaxRebate(filterInOEM(eligibleIncentives))];
};
