import dateFormat from 'dateformat';
import { get } from 'lodash';
import { createModelSegment } from 'client/data/luckdragon/segment';
import { EdmundsGraphQLFederation } from 'client/data/graphql/graphql-client';
import { createDate, getCurrentDate, getDifferenceInDays } from 'site-modules/shared/utils/date-utils';
import { withMetrics } from 'client/data/api/api-metrics';
import { VisitorModel } from 'client/data/models/visitor';
import { isNode } from 'client/utils/environment';
import { CONTENT_TYPES } from 'site-modules/shared/constants/incentives';
import { gql } from '@apollo/client'; // eslint-disable-line
import PropTypes from 'prop-types';
import { EdmundsAPI } from 'client/data/api/api-client';
import { logger } from 'client/utils/isomorphic-logger';
import { objectToQueryString } from 'site-modules/shared/utils/string';
import { Storage } from 'site-modules/shared/utils/storage';
import { LocationModel } from 'client/data/models/location';

const STORAGE_KEY = 'incentivesWizard';
export const WIZARD_STATE_LIFETIME_DAYS = 3;

const IncentiveData = PropTypes.shape({
  programType: PropTypes.string,
  subtype: PropTypes.string,
  subtypeId: PropTypes.number,
  rebateAmount: PropTypes.number,
  endDate: PropTypes.string,
  restrictions: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.string,
  transactionTypes: PropTypes.arrayOf(PropTypes.string),
  grantor: PropTypes.string,
  updateDate: PropTypes.string,
  compatibleIncentiveIds: PropTypes.arrayOf(PropTypes.string),
  primary: PropTypes.bool,
  programUrl: PropTypes.string,
  rebateOverridesByEligibility: PropTypes.arrayOf(
    PropTypes.shape({
      rebateAmount: PropTypes.number,
      eligibilityFactor: PropTypes.shape({
        qualifier: PropTypes.string,
        id: PropTypes.string,
        taxFilingStatus: PropTypes.string,
        maxIncome: PropTypes.number,
        householdSize: PropTypes.number,
        assistanceParticipant: PropTypes.bool,
        qualifierDescription: PropTypes.string,
      }),
    })
  ),
});
const IncentivesData = PropTypes.arrayOf(IncentiveData);

const IncentiveFilters = PropTypes.shape({
  zip: PropTypes.string,
  activeDate: PropTypes.string,
  makeSlug: PropTypes.string,
  modelSlug: PropTypes.string,
  inventoryCode: PropTypes.string,
  engineTypes: PropTypes.arrayOf(PropTypes.string),
  subtypeIds: PropTypes.arrayOf(PropTypes.number),
  excludeSubtypeIds: PropTypes.arrayOf(PropTypes.number),
});
const EligibilityFactor = PropTypes.shape({
  qualifier: PropTypes.string,
  assistanceParticipant: PropTypes.bool,
  taxFilingStatus: PropTypes.string,
  income: PropTypes.number,
});

const EligibilityFactors = PropTypes.arrayOf(EligibilityFactor);

const IncentivesWizardFilters = PropTypes.shape({
  incentiveFilters: IncentiveFilters,
  eligibilityFactors: EligibilityFactors,
});

const IncentivesWizardUnwrappedPersistedState = PropTypes.shape({
  incentives: IncentivesData,
  eligibleIncentivesIds: PropTypes.arrayOf(PropTypes.string),
});

const IncentivesWizardPersistedState = PropTypes.shape({
  incentives: PropTypes.arrayOf(PropTypes.string),
  eligibleIncentivesIds: PropTypes.arrayOf(PropTypes.string),
});

export const IncentivesWizardEntities = {
  IncentiveData,
  IncentivesData,
  IncentivesWizardFilters,
  IncentivesWizardPersistedState,
  IncentivesWizardUnwrappedPersistedState,
};

const getDefaultActiveDate = () => 'defaultActiveDate';
const getIncentivesWizardFilters = () => 'incentivesWizardFilters';
const getVehicle = () => 'vehicle';
const getIncentivesWizardData = () => 'incentivesWizardData';
const getWizardPhoto = () => 'wizardPhoto';
const getPersistedWizardState = () => 'persistedWizardState';

export const IncentivesWizardPaths = {
  getDefaultActiveDate,
  getIncentivesWizardFilters,
  getIncentivesWizardData,
  getWizardPhoto,
  getVehicle,
  getPersistedWizardState,
};

const REBATE_ELIGIBILITY_FRAGMENTS = {
  rebateOverridesByEligibilitySegmentFields: gql`
    fragment rebateOverridesByEligibilityFields on Incentive {
      rebateOverridesByEligibility {
        rebateAmount
        eligibilityFactor {
          ... on EligibilityFactor {
            qualifier
            id
            taxFilingStatus
            maxIncome
            householdSize
            assistanceParticipant
            qualifierDescription
          }
        }
      }
    }
  `,
  topIncentivesByRebateFields: gql`
    fragment incentiveFields on Incentive {
      programType
      subtype
      subtypeId
      rebateAmount
      endDate
      restrictions
      id
      type
      transactionTypes
      grantor
      updateDate
      compatibleIncentiveIds
      primary
      programUrl
      ...rebateOverridesByEligibilityFields
    }
  `,
};

export const getGqlIncentivesWizardData = (context, variables) =>
  withMetrics(EdmundsGraphQLFederation, context).query(
    gql`
      ${REBATE_ELIGIBILITY_FRAGMENTS.rebateOverridesByEligibilitySegmentFields}
      ${REBATE_ELIGIBILITY_FRAGMENTS.topIncentivesByRebateFields}
      query(
        $incentiveFilters: IncentiveFilters!
        $eligibilityFactors: [EligibilityFactorFilter!]
        $page: PageRequest!
        $sortBy: IncentiveAggregationByGrantorAndSubtypeSort!
      ) {
        incentives(incentiveFilters: $incentiveFilters, eligibilityFactors: $eligibilityFactors) {
          incentiveAggregations {
            byGrantorAndSubtype(page: $page, sortBy: $sortBy) {
              topIncentiveByRebate {
                ...incentiveFields
              }
            }
          }
        }
      }
    `,
    {
      ...variables,
    }
  );

const getSubtypeIdsFilter = incentivesFilter => {
  const { excludeSubtypeIds, subtypeIds } = incentivesFilter;
  if (!(excludeSubtypeIds || subtypeIds)) {
    return undefined;
  }

  return excludeSubtypeIds ? { notIn: excludeSubtypeIds } : { in: subtypeIds };
};

const getGqlIncentiveWizardFilters = incentivesFilter => ({
  zip: incentivesFilter.zip,
  makeSlugs: !incentivesFilter.makeSlug ? undefined : { in: [incentivesFilter.makeSlug] },
  modelSlugs: !incentivesFilter.modelSlug ? undefined : { in: [incentivesFilter.modelSlug] },
  styleIds: !incentivesFilter.styleId ? undefined : [incentivesFilter.styleId],
  engineTypes: incentivesFilter.engineTypes,
  activeDate: incentivesFilter.activeDate,
  transactionTypes: !incentivesFilter.transactionType ? undefined : { in: [incentivesFilter.transactionType] },
  types: { notIn: [CONTENT_TYPES.CUSTOMER_APR, CONTENT_TYPES.DEALER_CASH] },
  inventoryCodes: !incentivesFilter.inventoryCode ? undefined : incentivesFilter.inventoryCode.split(','),
  subtypeIds: getSubtypeIdsFilter(incentivesFilter),
  programTypesFilter: incentivesFilter?.programTypes?.length ? { in: incentivesFilter.programTypes } : undefined,
});

export const IncentivesWizardModel = createModelSegment('incentivesWizard', [
  {
    path: 'defaultActiveDate',
    async resolve(match, context) {
      if (!isNode()) {
        return dateFormat(createDate(getCurrentDate()), 'yyyy-mm-dd');
      }
      const visitorLocation = await context.resolveValue('location', VisitorModel);
      // defaulting to 0 so that the math below doesn't return NAN if gmtOffset is undefined or null.
      const gmtOffset = get(visitorLocation, 'gmtOffset', 0);
      return dateFormat(createDate(getCurrentDate().getTime() + gmtOffset * 60 * 60 * 1000), 'yyyy-mm-dd');
    },
  },

  /**
   * The path contains vehicle details
   * @see getVehicle
   */
  {
    path: 'vehicle',
  },

  /**
   * The path contains only filters that pass to graphQL call
   * @see getIncentivesWizardFilters
   */
  {
    path: 'incentivesWizardFilters',
    async resolve(match, context) {
      const [visitorLocation, activeDate] = await Promise.all([
        context.resolveValue('location', VisitorModel, false),
        context.resolveValue(getDefaultActiveDate(), IncentivesWizardModel, false),
      ]);

      return { incentiveFilters: { zip: visitorLocation?.zipCode, stateCode: visitorLocation?.stateCode, activeDate } };
    },
    async update(newValue, match, context) {
      if (!newValue) {
        return newValue;
      }

      const { incentiveFilters } = await context.resolveValue(getIncentivesWizardFilters());

      const { incentiveFilters: newIncentiveFilters = {}, eligibilityFactors = [], random } = newValue;

      if (newIncentiveFilters.zip && newIncentiveFilters.zip !== incentiveFilters.zip) {
        try {
          const location = await context.resolveValue(`zips["${newIncentiveFilters.zip}"]`, LocationModel, false);
          newIncentiveFilters.stateCode = location.stateCode;
        } catch (e) {
          logger('error', e);
        }
      }

      return {
        incentiveFilters: { ...incentiveFilters, ...newIncentiveFilters },
        eligibilityFactors,
        random,
      };
    },
  },
  /**
   * @see getIncentivesWizardData
   */
  {
    path: 'incentivesWizardData',
    async resolve(match, context) {
      const [{ incentiveFilters: incentiveWizardFilters, eligibilityFactors }, vehicle = {}] = await Promise.all([
        context.resolveValue(getIncentivesWizardFilters()),
        context.resolveValue(getVehicle()),
      ]);

      const incentiveFilters = getGqlIncentiveWizardFilters({ ...vehicle, ...incentiveWizardFilters });
      const incentivesResponse = await getGqlIncentivesWizardData(context, {
        incentiveFilters,
        eligibilityFactors,
        page: { pageNum: 1, pageSize: 16 },
        sortBy: { topIncentiveByRebateAmount: 'DESC' },
      });

      return get(incentivesResponse, 'incentives.incentiveAggregations.byGrantorAndSubtype', []).map(incentive =>
        get(incentive, 'topIncentiveByRebate')
      );
    },

    async update(newValue, match, context) {
      // This is necessary to be able to set the initial value for the path on
      // the client side before binding to the model, so that the necessary dependencies (incentivesWizardFilters, zip)
      // are installed in the path and the resolve method is called in this path when the filters change.

      await Promise.all([context.resolveValue(getIncentivesWizardFilters())]);

      return newValue;
    },
  },
  /**
   * @see getWizardPhoto
   * https://www.edmunds.com/gateway/api/media/v2/honda/civic/2024/photos/?pageNum=1&pageSize=1&width=815&provider=OEM,EVOX&photoprovider=OEM,EVOX&sortby=photoprovider:asc&shottype=TDS,FQ
   */
  {
    path: 'wizardPhoto',
    async resolve(match, context) {
      const { makeSlug, modelSlug, year } = await context.resolveValue(getVehicle());
      const params = objectToQueryString({
        pageNum: 1,
        pageSize: 1,
        width: 300,
        provider: ['OEM', 'EVOX'],
        photoprovider: ['OEM', 'EVOX'],
        sortby: 'photoprovider:desc',
        shottype: ['TDS', 'FQ'],
      });
      return withMetrics(EdmundsAPI, context)
        .fetchJson(`/media/v2/${makeSlug}/${modelSlug}/${year}/photos?${params}`)
        .then(photoResponse => get(photoResponse, 'photos[0].sources[0].link.href', ''))
        .catch(e => {
          logger('error', e);
          return '';
        });
    },
  },
  /**
   * @see getPersistedWizardState
   */
  {
    path: 'persistedWizardState',
    async resolve() {
      if (isNode()) {
        return undefined;
      }

      const storage = new Storage('localStorage');

      const wizardState = storage.get(STORAGE_KEY);

      if (!wizardState) {
        return {};
      }

      const { savingDate } = wizardState;

      if (!savingDate || getDifferenceInDays(createDate(savingDate)) > WIZARD_STATE_LIFETIME_DAYS) {
        storage.remove(STORAGE_KEY);
        return {};
      }

      return wizardState;
    },
    async update(wizardState) {
      const storage = new Storage('localStorage');

      if (!wizardState) {
        storage.remove(STORAGE_KEY);
        return wizardState;
      }

      const wizardStateWithSavingDate = {
        ...wizardState,
        savingDate: dateFormat(getCurrentDate(), 'mm/dd/yyyy'),
      };

      storage.set(STORAGE_KEY, wizardStateWithSavingDate);

      return wizardStateWithSavingDate;
    },
  },
]);
