import React, { useCallback, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { noop } from 'lodash';
import convexMask from 'site-modules/shared/components/clipped-container/mask-library/convex-header';
import { DrawerV2 } from 'site-modules/shared/components/drawer/drawer-v2';
import { WhiteBackButton } from 'site-modules/shared/components/lead-form/lead-form-drawer/close-buttons/white-back-button/white-back-button';
import { ConvexHeader } from 'site-modules/shared/components/lead-form/unified-lead-form/components/header/convex-header/convex-header';
import { WizardContentAsync } from 'site-modules/shared/components/inventory/incentives-wizard/wizard-content/wizard-content-async';
import { wizardTrackingIds } from 'client/tracking/maps/incentives-wizard';

import './incentives-wizard-drawer.scss';

export function IncentivesWizardDrawer({
  questions,
  stepFactoryComponentsMap,
  onRebatesSaveSum,
  onToggle,
  isOpen,
  isMobile,
  make,
  model,
  year,
  bodyType,
  initialStepIndex,
  component: Component,
  creativeId,
  type,
  engineTypes,
  initialEligibleIncentivesIds,
  savedAnswers,
  onStartOver,
}) {
  const [startConvexAnimation, setStartConvexAnimation] = useState(false);
  const drawerRef = useRef();
  const scrollToTop = useCallback(() => {
    drawerRef.current?.content?.scrollTo(0, 0);
  }, []);
  const context = useMemo(() => ({ make, model, year, bodyType, type, engineTypes }), [
    bodyType,
    engineTypes,
    make,
    model,
    type,
    year,
  ]);

  return (
    <DrawerV2
      isOpen={isOpen}
      side={isMobile ? 'bottom' : 'right'}
      size={isMobile ? 'small' : 'medium'}
      toggle={onToggle}
      classNames={{ drawerContent: 'wizard-drawer-content bg-cool-gray-90 px-1 pb-1' }}
      data-tracking-parent={creativeId}
      data-no-widget-view
      ariaLabel="Check eligibility for ev tax credits, rebates and other incentives"
      drawerRef={drawerRef}
      unmountOnExit
    >
      <ConvexHeader
        innerMask={{ name: 'wizard-convex-header', path: convexMask.path }}
        className={classnames('convex-header', { 'convex-animation': startConvexAnimation })}
      />
      <WhiteBackButton
        onClick={onToggle}
        className="ms-1_5"
        dataTrackingId={wizardTrackingIds.closeDrawer}
        dataTrackingValue="close"
      />
      <div className="wizard-content-wrapper rounded-16 mx-auto mt-1">
        <Component
          questions={questions}
          stepFactoryComponentsMap={stepFactoryComponentsMap}
          onRebatesSaveSum={onRebatesSaveSum}
          onExit={onToggle}
          context={context}
          initialStepIndex={initialStepIndex}
          creativeId={creativeId}
          onStartConvexAnimation={setStartConvexAnimation}
          onScrollToTop={scrollToTop}
          initialEligibleIncentivesIds={initialEligibleIncentivesIds}
          savedAnswers={savedAnswers}
          onStartOver={onStartOver}
        />
      </div>
    </DrawerV2>
  );
}

IncentivesWizardDrawer.propTypes = {
  questions: PropTypes.shape({}),
  onRebatesSaveSum: PropTypes.func,
  onToggle: PropTypes.func,
  isOpen: PropTypes.bool,
  isMobile: PropTypes.bool,
  make: PropTypes.string,
  model: PropTypes.string,
  year: PropTypes.number,
  initialStepIndex: PropTypes.number,
  component: PropTypes.elementType,
  bodyType: PropTypes.string,
  type: PropTypes.string,
  engineTypes: PropTypes.arrayOf(PropTypes.string),
  creativeId: PropTypes.string,
  initialEligibleIncentivesIds: PropTypes.arrayOf(PropTypes.string),
  savedAnswers: PropTypes.shape({}),
  onStartOver: PropTypes.func,
  stepFactoryComponentsMap: PropTypes.shape({}).isRequired,
};
IncentivesWizardDrawer.defaultProps = {
  questions: undefined,
  onRebatesSaveSum: noop,
  onToggle: noop,
  isOpen: false,
  isMobile: false,
  make: '',
  model: '',
  year: undefined,
  initialStepIndex: undefined,
  component: WizardContentAsync,
  bodyType: '',
  creativeId: '',
  type: undefined,
  engineTypes: undefined,
  initialEligibleIncentivesIds: [],
  savedAnswers: undefined,
  onStartOver: noop,
};
